/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Menu, Text, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Title, SeparateLine, SeparateLineWrap, Fullmap, FullmapWrap, FullmapCover, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"} description={"Kontaktní údaje kamnářství"}>
        <Column className="pt--10" name={"kontakt-oddelovaci-cara"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--l8w" name={"abz4m8e7xxq"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingRight":1013}} columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":100}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17440/3ab51f3e27d2409c9d1285cf1d7de2a4_s=860x_.png"} url={"https://www.facebook.com/profile.php?id=61553456535181"} use={"url"} href={"https://www.facebook.com/profile.php?id=61553456535181"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/17440/3ab51f3e27d2409c9d1285cf1d7de2a4_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/3ab51f3e27d2409c9d1285cf1d7de2a4_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/3ab51f3e27d2409c9d1285cf1d7de2a4_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/3ab51f3e27d2409c9d1285cf1d7de2a4_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/3ab51f3e27d2409c9d1285cf1d7de2a4_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":100}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17440/4329fe0dae7c46118ba5be9233e81062_s=860x_.png"} url={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} use={"url"} href={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/17440/4329fe0dae7c46118ba5be9233e81062_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/4329fe0dae7c46118ba5be9233e81062_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/4329fe0dae7c46118ba5be9233e81062_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/4329fe0dae7c46118ba5be9233e81062_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/4329fe0dae7c46118ba5be9233e81062_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu" menu={true} name={"kontakt-hlavicka"}>
          
          <Menu style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">DANIEL VLÁHA +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"KAMNÁŘSTVÍ NA ROZKOŠI"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/nabizime"} style={{"maxWidth":125}} target={""} content={"nabízíme"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/cenik"} style={{"maxWidth":125}} target={""} content={"ceník"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/poradna"} style={{"maxWidth":140}} target={""} content={"poradna"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/galerie"} style={{"maxWidth":140}} target={""} content={"GALERIE"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/aktuality"} style={{"maxWidth":125}} target={""} content={"AKTUALITY"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/o-nas"} style={{"maxWidth":125}} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/kontakt"} style={{"maxWidth":125}} target={""} content={"kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"kontakt_cech"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--14" content={"člen Cechu kamnářů České republiky"}>
              </Title>

              <Image className="mt--08" src={"https://cdn.swbpg.com/o/17440/5c02d8768d2342198c276b8957ed27a4.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":60}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"kontakt-oddelovac-1"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--menu" menu={true} name={"kontakt-rozcestnik"} style={{"left":"0","zIndex":"2","overflow":"visible","position":"absolute"}}>
          
          <Menu >
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--style5 --center">
              
              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kamna"} target={""} content={"KAMNA"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/sporaky"} target={""} content={"SPORÁKY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kombinovana_topidla"} target={""} content={"KOMBINOVANÁ TOPIDLA"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/krby"} target={""} content={"KRBY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kominy"} target={""} content={"KOMÍNY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/speciality"} target={""} content={"SPECIALITY"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"fosdccfl4it"}>
        </SeparateLine>


        <SeparateLine className="pb--10" name={"kontakt-oddelovac-2"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-wfwkdl --parallax pt--20" name={"kontakt-udaje"} layout={"l2"} parallax={true} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/629336db3df54125903951a4b58b8aad_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/629336db3df54125903951a4b58b8aad_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/629336db3df54125903951a4b58b8aad_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/629336db3df54125903951a4b58b8aad_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/629336db3df54125903951a4b58b8aad_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/629336db3df54125903951a4b58b8aad_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/629336db3df54125903951a4b58b8aad_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/629336db3df54125903951a4b58b8aad_s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--3" style={{"color":"var(--color-variable-1)"}} content={"Rádi byste se dozvěděli více?"}>
              </Subtitle>

              <Title className="title-box" content={"KONTAKTUJTE NÁS"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/email-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} svgColor={"rgba(0,0,0,1)"} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"info@kamnarstvinarozkosi.cz"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">Co je psáno, to je dáno! Napište nám.<br>Odpovíme všem.<br></span><br><br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/t/17440/3cc8859803ea470ca9f8ce8e64628e54_s=860x_.png"} url={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} use={"url"} href={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":131}} srcSet={"https://cdn.swbpg.com/t/17440/3cc8859803ea470ca9f8ce8e64628e54_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/3cc8859803ea470ca9f8ce8e64628e54_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/3cc8859803ea470ca9f8ce8e64628e54_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/3cc8859803ea470ca9f8ce8e64628e54_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/3cc8859803ea470ca9f8ce8e64628e54_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(3, 3, 3);\">Jsme na <a href=\"https://www.instagram.com/kamnarstvi_na_rozkosi/\">Instagramu</a>. Sledujte nás.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/phone-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} svgColor={"rgba(0,0,0,1)"} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"+420 776 780 996"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">Telefon je rychlejší. <br>Pokud zrovna stavíme nějaká kamna, zavoláme zpět.<br></span><span style=\"color: rgb(0, 0, 0);\"><br></span>"}>
              </Text>

              <Image className="--center" alt={""} src={"https://cdn.swbpg.com/t/17440/5907eb2f16134f6bbf4c914634b67f11_s=860x_.png"} url={"https://www.facebook.com/profile.php?id=61553456535181"} use={"url"} href={"https://www.facebook.com/profile.php?id=61553456535181"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":131}} srcSet={"https://cdn.swbpg.com/t/17440/5907eb2f16134f6bbf4c914634b67f11_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/5907eb2f16134f6bbf4c914634b67f11_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/5907eb2f16134f6bbf4c914634b67f11_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/5907eb2f16134f6bbf4c914634b67f11_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/5907eb2f16134f6bbf4c914634b67f11_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">Jsme také na <a href=\"https://www.facebook.com/profile.php?id=61553456535181\" target=\"_blank\">Facebooku</a>. Přidejte se k nám.<br></span><span style=\"color: rgb(0, 0, 0);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/adress-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} svgColor={"rgba(0,0,0,1)"} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Šeřeč 3, 552 03 Česká Skalice"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">Víte, že Babiččino údolí skutečně existuje? A že tu máme Rozkoš i Peklo? Stavte se, uvidíte.</span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--14 mt--16" href={"#kontakt-fakturace"} target={null} content={"FAKTURAČNÍ ÚDAJE"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"fys310oc0b"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <SeparateLine className="pb--10 pt--10" name={"kontakt-oddelovac-3"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--10 pt--10" name={"kontakt-mapa-formular"} layout={"l5"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="--right">
              
              <FullmapCover zoom={"11"} place={"Šeřeč"} style={{"overflow":"hidden","borderRadius":"6px"}}>
              </FullmapCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"kontakt-oddelovac-4"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-ke0ljt --parallax pb--60 pt--60" name={"kontakt-fakturace"} parallax={true} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/01bbb7a989b2416ba48cd2433a0f2e21_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/01bbb7a989b2416ba48cd2433a0f2e21_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/01bbb7a989b2416ba48cd2433a0f2e21_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/01bbb7a989b2416ba48cd2433a0f2e21_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/01bbb7a989b2416ba48cd2433a0f2e21_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/01bbb7a989b2416ba48cd2433a0f2e21_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/01bbb7a989b2416ba48cd2433a0f2e21_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/17440/01bbb7a989b2416ba48cd2433a0f2e21_s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--3 fs--43" content={"Fakturační údaje<br><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left ff--3 fs--20" style={{"maxWidth":334}} content={"DANIEL VLÁHA<br>Kamnářství na Rozkoši<br><br>Šeřeč 3<br>552 03 Česká Skalice<br>Česká republika<br><br>mail: info@kamnarstvinarozkosi.cz\ntel.: +420 776 780 996<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left ff--3 fs--20" style={{"maxWidth":334}} content={"IČ: 74214314<br>DIČ: CZ7912303278<br><br>bankovní spojení: 2401917994/2010<br>b.ú. veden u Fio banka a.s.<br><br>Zapsaný v živnostenském rejstříku u Městského úřadu Náchod.<br><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"kontakt-oddelovac-5"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--menu pb--08 pt--20" menu={true} name={"kontakt-zapati"}>
          
          <Menu style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">Daniel vláha +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"kamnářství na rozkoši"}>
              </Subtitle>

              <Subtitle className="subtitle-box ff--3 fs--12 mt--06" content={"člen Cechu kamnářů České republiky"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton className="--hvr2 --filling2">
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/nabizime"} target={null} content={"NABÍZÍME"}>
                </Button>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/kontakt"} target={null} content={"KONTAKT"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" name={"kontakt-mesta"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center ff--3 fs--14 swpf--uppercase" content={"NÁCHOD - ČESKÁ SKALICE - NOVÉ MĚSTO NAD METUJÍ - ČERVENÝ KOSTELEC - BROUMOV - TRUTNOV - DOBRUŠKA - OPOČNO - RYCHNOV NAD KNĚŽNOU - POLICE NAD METUJÍ"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--10" name={"kontakt-oddelovac-6"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pt--0" style={{"paddingBottom":2,"backgroundColor":"var(--color-variable-1)"}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kontakt-paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--11" href={"/zasady-ochrany-osobnich-udaju"} target={null} content={"ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ"}>
              </Button>

              <Text className="text-box ff--3 mt--06" style={{"color":"rgb(0, 0, 0)"}} content={"Přečtěte si podrobné informace o našich Zásadách ochrany osobních údajů před dalším užíváním těchto stránek.<br>Veškeré zde zveřejněné informace a fotografie jsou majetkem Kamnářství na Rozkoši. Jejich šíření je bez souhlasu majitele zakázáno! Děkujeme, že respektujete autorská práva.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}